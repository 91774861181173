.filter-bar {
	width: 90%;
	margin: 8px 5% 0 5%;
	height: auto;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	flex-wrap: wrap;

	.filter-element {
		padding: 4px 12px 4px 12px;
		border: 1px solid getcolor('primary');
		border-radius: 25px;
		font-size: 12px;
		margin-right: 8px;
		margin-bottom: 4px;
		line-height: 1;
		display: flex;
		justify-content: center;
		align-items: center;

		.remove-entry {
			position: relative;
			top: 1px;

			i {
				font-size: 14px;
				color: getcolor('danger');
			}
		}
	}
}