.event-filter {
	color: getcolor('darkFont');
	margin: 16px 5% 0 5%;
	font-size: 14px;

	@include minMediaquery('large') {
		margin: 16px 20% 0 20%;
	}

	.event-filter-nav {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		h3 {
			text-decoration: underline;
		}

		.button-group {
			width: 30%;
		}
	}

	.event-filter-date {
		margin-top: 16px;
		margin-bottom: 16px;

		input[type="date"] {
			padding: 8px 4px 8px 4px;
			border: none;
			background: getcolor('lightgrey');

			&:first-child {
				border-radius: 25px 0 0 25px;
			}

			&:last-child {
				border-radius: 0 25px 25px 0;
			}

			&:focus {
				outline: none;
			}
		}
	}

	.event-filter-location, .event-filter-eventKind {
		margin-bottom: 16px;
	}

	.event-filter-select {
		color: getcolor('darkFont');
		background-color: getcolor('lightgrey');

		&.open {
			color: getcolor('lightFont');
			background-color: getcolor('secondary');
			border-radius: 20px 20px 0 0;

			:nth-child(2) {
				border-left: 1px solid getcolor('lightFont');
			}
		}

		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 5px 10px 5px 10px;
		border-radius: 25px;

		:first-child {
			position: relative;
			margin: auto 0;
		}

		:nth-child(2) {
			display: flex;
			border-left: 1px solid getcolor('darkFont');
		}

		&.online {
			width: 210px;


			:nth-child(2) {
				border: none;
			}
		}
	}

	.event-filter-online-location {
		margin-bottom: 16px;
	}

	.event-filter-open-select {
		background-color: getcolor('secondary');
		color: getcolor('lightFont');
		border-radius: 0 25px 25px 0;
		width: 100%;
	}

	.event-filter-buttons {
		display: flex;
		flex-direction: row;
		justify-content: left;
		color: getcolor('lightFont');

		div {
			padding: 8px 16px 8px 16px;
			border-radius: 25px;
			margin-right: 12px;
			font-size: 12px;
			@include minMediaquery('medium') {
				font-size: inherit;
			}
		}

		:first-child {
			background-color: getcolor('secondary');
		}

		:last-child {
			background-color: getcolor('cancel');

		}
	}

	.event-filter-selection {

		h3 {
			text-decoration: underline;
			margin: 16px 0 16px 0;
		}

		.event-filter-selection-list {
			width: 100%;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			margin-bottom: 16px;


			.date-view {
				padding: 8px 8px 8px 16px;
				background: getcolor('white');
				border: 2px solid getcolor('lightgrey');
				border-radius: 25px;
				margin: 0 8px 8px 0;
			}

			.remove {
				color: getcolor('cancel');
				font-size: 14px;
			}

			.date-view-seperator {
				position: relative;
				margin: auto 0;
			}

			.location-view {
				padding: 8px 8px 8px 16px;
				background: getcolor('white');
				border: 2px solid getcolor('lightgrey');
				border-radius: 25px;
				margin: 0 8px 8px 0;

			}
		}
	}
}