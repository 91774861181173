.event-list {
	width: 90%;
	height: auto;
	margin: 0 5% 0 5%;

	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	$breakpoint: map-get($breakpoints, 'medium');
	--multiplier: calc(#{$breakpoint} - 100%);

	@include minMediaquery('medium') {
		.event-card {
			margin-left: 25px;
			margin-right: 25px;
		}
	}


}

.no-more-results {
	padding: 32px 0 32px 0;
}
