.event-card {
	background-color: getcolor('primary');
	color: getcolor('lightFont');
	margin-top: 24px;
	border-radius: 31px 0 0 0;
	position: relative;
	animation: appear-up .75s ease;
	width: 100%;
	min-width: 29%;
	margin-left: auto;
	margin-right: auto;
	flex-grow: 1;

	flex-basis: calc(var(--multiplier) * 9999);

	display: flex;
	flex-direction: column;


	.event-card-header {
		display: flex;
		flex-direction: row;
		width: 100%;
		margin-bottom: 16px;

		.event-base-info {
			width: 100%;

			> h3 {
				padding: 0 16px 0 16px;
				color: getcolor('lightFont');
				font-size: 16px;
				margin: 8px 8px 16px 0;
				text-align: center;
				line-height: 1.4;
			}

			.date-location {
				width: 100%; //
				//width: calc(100% - 4px);
				background-color: getcolor('white');
				color: getcolor('darkFont');
				font-size: 14px;
				padding: 5px 0 5px 0;
				//position: relative;
				//left: 2px;

				> h3 {
					text-align: center;
					line-height: 1.3;
					font-weight: bold;
					color: getcolor('darkFont');
					font-size: 16px;
				}
			}
		}
	}

	.event-image-and-description {
		display: flex;
		flex-direction: row;

		.event-image {
			width: 30%;
			margin-left: 16px;

			img {
				width: 100%;
			}
		}

		.event-description {
			width: 70%;
			padding: 0 0 0 4%;
			line-height: 1.2em;

			p {
				margin-right: 16px;
				font-size: 14px;
				line-height: 1.5em;
			}
		}
	}

	.event-card-footer {
		padding: 0 2% 10px 2%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-top: auto;

		.event-card-footer-pictograms {
			display: flex;
			flex-direction: row;


			div {
				border-radius: 50%;
				width: 25px;
				height: 25px;
				position: relative;
				margin-right: 5px;

				@include minMediaquery('medium') {
					width: 32px;
					height: 32px;
				}

				img {
					width: 20px;
					position: absolute;
					top: 2.5px;
					left: 2.5px;

					@include minMediaquery('medium') {
						width: 30px;
					}
				}

				i {
					font-size: 23px;
					color: getcolor('white');

					@include minMediaquery('medium') {
						font-size: 28px;
					}
				}
			}
		}

		.event-card-footer-more {
			background-color: getcolor('white');
			color: getcolor('darkFont');
			padding: 3px 10px 3px 10px;
			border-radius: 10px;
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 25px;
			z-index: 999;

			div {
				font-size: 14px;
				line-height: 1;
			}
		}
	}

	.event-card-canceled {
		position: absolute;
		text-align: center;
		width: 100%;
		height: 100%;
		border-radius: 30px 0 0 0;
		background-color: rgba(255, 255, 255, 0.6);
		pointer-events: none;

		h2 {
			color: getcolor('cancel2');
			width: 100%;
			position: absolute;
			transform: rotate(-45deg);
			top: 45%;
			font-size: 40px;
			font-weight: bold;
			text-transform: uppercase;

		}
	}
}