$primaryColor: #274156;

$colors: (
	"primary": $primaryColor,
	"secondary": #6aa9fd,
	"lightFont": #fff,
	"darkFont": #000,
	"lightgrey": #ededed,
	"cancel": #D16565,
	"cancel2": #C74343,
	"light": #fafafa,
	"white": #fff,
	"success": #28a745,
	"warning": #ffc107,
	"danger": #ff0000,
	"helpBackground": rgba($primaryColor, .95),
);

$breakpoints: (
	'small': 638px,
	'medium': 768px,
	'large': 992px,
	'huge': 1200px,
);


$minBreakpoints: (
	'small': (min-width: map-get($breakpoints, 'small')),
	'medium': (min-width: map-get($breakpoints, 'medium')),
	'large': (min-width: map-get($breakpoints, 'large')),
	'huge': (min-width: map-get($breakpoints, 'huge')),
);

$maxBreakpoints: (
	'small': (max-width: map-get($breakpoints, 'small')),
	'medium': (max-width: map-get($breakpoints, 'medium')),
	'large': (max-width: map-get($breakpoints, 'large')),
	'huge': (max-width: map-get($breakpoints, 'huge')),
);

@mixin minMediaquery($breakpoint) {
	$raw-query: map-get($minBreakpoints, $breakpoint);

	@if $raw-query {
		$query: if(
				type-of($raw-query) == 'string',
				unquote($raw-query),
				inspect($raw-query)
		);

		@media #{$query} {
			@content;
		}
	} @else {
		@error 'No value found for `#{$breakpoint}`. '
         + 'Please make sure it is defined in `$breakpoints` map.';
	}
}

@mixin maxMediaquery($breakpoint) {
	$raw-query: map-get($maxBreakpoints, $breakpoint);

	@if $raw-query {
		$query: if(
				type-of($raw-query) == 'string',
				unquote($raw-query),
				inspect($raw-query)
		);

		@media #{$query} {
			@content;
		}
	} @else {
		@error 'No value found for `#{$breakpoint}`. '
         + 'Please make sure it is defined in `$breakpoints` map.';
	}
}