.event-filter-eventkind {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	flex-wrap: wrap;
	height: auto;
	width: 100%;

	.event-filter-open-select {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		flex-wrap: wrap;
		height: auto;
		padding: 8px 8px 16px 8px;
	}

	.filter-eventkind-entry {
		padding: 4px 12px 4px 12px;
		border: 1px solid getcolor('lightFont');
		border-radius: 25px;
		margin-top: 8px;
		cursor: pointer;
		margin-right: 8px;

		&.checked {
			color: getcolor('darkFont');
			background-color: getcolor('lightFont');
		}
	}
}