@keyframes slide-up {
	0% {
		//opacity: 0;
		max-height: 0;
	}
	100% {
		//opacity: 1;
		max-height: 400px;
	}
}

@keyframes slide-down-remove {
	0% {
		max-height: 400px;
	}
	100% {
		max-height: 0;
	}
}

@keyframes appear-up {
	0% {
		opacity: 0;
		transform: translateY(20px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes slide {
	0% {
		max-height: 0;
		opacity: 0;
	}
	100% {
		max-height: 40px;
		opacity: 1;
	}
}

.slide-enter-active {
	animation: slide .25s;
}

.slide-leave-active {
	animation: slide .25s reverse;
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.fade-in-enter-active {
	animation: fade-in .25s;
}

.fade-in-leave-active {
	animation: fade-in .25s reverse;
}