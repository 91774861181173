.lraApp-noscroll {
	overflow: hidden;
	max-height: 100vh;
}

.lraApp {

	$FontPathOpenSans: "../../node_modules/open-sans-fonts/open-sans";
	@import '../../node_modules/open-sans-fonts/open-sans.scss';

	$material-icons-font-path: '~material-icons/iconfont/';
	@import '~material-icons/iconfont/material-icons.css';


	@import 'abstracts/mixins';
	@import 'abstracts/config-frontend2';

	@import 'abstracts/frontend_keyframes';

	//Component imports
	@import 'vue_components/frontend2/ImageHeader';
	@import 'vue_components/frontend2/NavBar';
	@import 'vue_components/frontend2/FilterBar';
	@import 'vue_components/frontend2/EventList';
	@import 'vue_components/frontend2/EventCard';
	@import 'vue_components/frontend2/PopupManager';
	@import 'vue_components/frontend2/SearchFilter';
	@import 'vue_components/frontend2/LocationSelector';
	@import 'vue_components/frontend2/EventKindSelector';
	@import 'vue_components/frontend2/Icon';
	@import 'vue_components/frontend2/EventPopup';
	@import 'vue_components/frontend2/ToTop';
	@import 'vue_components/frontend2/Help';

	* {
		padding: 0;
		margin: 0;
		font-family: "Open-sans, Verdana, Helvetica", sans-serif;
		line-height: 1;
	}

	.material-icons {
		font-family: 'Material Icons';
		font-weight: normal;
		font-style: normal;
		font-size: 24px; /* Preferred icon size */
		display: inline-block;
		line-height: 1;
		text-transform: none;
		letter-spacing: normal;
		word-wrap: normal;
		white-space: nowrap;
		direction: ltr;
		-webkit-font-smoothing: antialiased;
		text-rendering: optimizeLegibility;
		-moz-osx-font-smoothing: grayscale;
		font-feature-settings: 'liga';
	}

	article {
		&.calendar-border {
			position: relative;
			width: 100%;
		}
	}

	a {
		color: inherit;

	}

	.no-select {
		user-select: none;
	}

	.clickable {
		cursor: pointer;
		user-select: none;
	}

	.buttonFix {
		position: relative;
		top: 3px;
	}

	.button-group {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: right;

		div {
			margin-left: 16px;
		}
	}


	.switch {
		position: relative;
		display: inline-block;
		width: 48px;
		height: 24px;
	}

	.switch input {
		opacity: 0;
		width: 0;
		height: 0;
	}

	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ccc;
		-webkit-transition: .4s;
		transition: .4s;
	}

	.slider:before {
		position: absolute;
		content: "";
		height: 16px;
		width: 16px;
		left: 4px;
		bottom: 4px;
		background-color: getcolor('darkFont');
		-webkit-transition: .4s;
		transition: .4s;
	}

	input:checked + .slider {
		background-color: getcolor('dark');

		&:before {
			background-color: getcolor('success');
		}

		&.danger {
			background-color: getcolor('danger');
		}

		&.warning {
			background-color: getcolor('warning');
		}
	}

	input:focus + .slider {
		box-shadow: 0 0 1px getcolor('dark');
	}

	input:checked + .slider:before {
		transform: translateX(24px);
	}

	.slider.round {
		border-radius: 28px
	}

	.slider.round:before {
		border-radius: 50%;
	}

	.onclick-link {
		text-decoration: none;
	}
}