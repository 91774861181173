.event-filter-location-search {
	border-radius: 25px;
	margin: 16px 0 0 8px;
	padding-left: 12px;
	border: none;
	height: 25px;
}

.filter-location-border {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	flex-wrap: wrap;
	height: auto;
	padding: 8px 8px 16px 8px;

	.filter-location-entry {
		padding: 4px 12px 4px 12px;
		border: 1px solid getcolor('lightFont');
		border-radius: 25px;
		margin-top: 8px;
		cursor: pointer;
		margin-right: 8px;

		&.checked {
			color: getcolor('darkFont');
			background-color: getcolor('lightFont');
		}
	}
}