
.event-popup-slider-inner {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.event-popup-slider-nav {
	margin: auto;

	i {
		background-color: getcolor('secondary');
		color: getcolor('lightFont');
		border: none;
		border-radius: 12px;
		padding: 4px 12px 4px 12px;
	}
}

.navSlider {
	.agile {
		margin: auto;
	}
}

.event-popup-border {
	position: fixed;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);

	h1, h2, h3, h4, h5, h6 {
		color: getcolor('lightFont');
	}

	top: 0;
	left: 0;


	.event-popup-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 16px 0 16px 0;

		> :first-child {
			padding-right: 4px;
		}
	}

	.event-popup-inner {
		background-color: getcolor('primary');
		color: getcolor('lightFont');
		border-radius: 25px 0 0 0;
		overflow-y: auto;
		margin: 10vh 5vw 10vh 5vw;
		height: 80vh;
		width: 90vw;
		@include minMediaquery('large') {
			width: 50vw;
			margin: 10vh 25vw 10vh 25vw;
		}

		.event-popup-spacer {
			width: 90%;
			margin-left: 5%;

			.event-popup-slider {
				width: 100%;
				padding-bottom: 16px;

				.navSlider {
					display: none;

					@include minMediaquery('large') {
						display: block;
						margin-top: 16px;
					}
				}
			}

			.agile {
				width: 70%;
				@include minMediaquery('medium') {
					width: 90%;
				}

				.agile__dot {
					border: none;
					width: 24px;
					height: 24px;
					padding: 8px;

					button {
						background-color: getcolor('lightGray');
						color: getcolor('lightFont');
						border: none;
						width: 100%;
						height: 100%;
						border-radius: 50%;

						&:hover {
							background-color: getcolor('secondary');
						}
					}

					&.agile__dot--current {
						button {
							background-color: getcolor('secondary');
						}
					}
				}

				.agile__nav-button {
					background-color: getcolor('secondary');
					color: getcolor('lightFont');
					border: none;
					border-radius: 12px;
					padding: 4px 12px 4px 12px;
				}

				.agile__track {
					padding: 1px;
				}

				.event-slide-image-canceled {
					position: absolute;
					text-align: center;
					width: 100%;
					height: 100%;
					background-color: rgba(255, 255, 255, 0.6);
					top: 0;

					h2 {
						color: getcolor('cancel2');
						width: 100%;
						position: absolute;
						transform: rotate(-45deg);
						top: 45%;
						font-size: 40px;
						font-weight: bold;
						text-transform: uppercase;

					}
				}

				.event-slide-image-container {
					width: 100%;
					overflow: hidden;
					display: flex;
					justify-content: center;

					.event-slide-image {
						max-height: 400px;
						max-width: 300px;

						@include minMediaquery('small') {
							max-height: 500px;
							max-width: 500px;
						}
					}
				}
			}
		}
	}
}

.event-popup-description {
	line-height: 1.5em;

	&.hasContent {
		padding-bottom: 24px;
	}

	p {
		line-height: 1.5em;
	}
}

.event-popup-details {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
	padding-bottom: 32px;

	h4 {
		padding-bottom: 8px;
		color: getcolor('lightFont');
	}

	.event-popup-details-event {
		min-width: 250px;
		margin-top: 8px;

		h4 {
			padding-top: 16px;
		}

		div {
			padding: 4px 16px 4px 0;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;

			i {
				position: relative;
				top: -3px;
			}

			> span {
				padding-left: 16px;
			}

			> a {
				padding-left: 16px;
			}
		}
	}

	.event-popup-details-organisator {
		margin-top: 8px;

		h4 {
			padding-top: 16px;
		}

		div {
			padding: 4px 0 4px 0;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;

			i {
				position: relative;
				top: -3px;
			}

			> span {
				padding-left: 16px;
				width: 95%;
			}

			> a {
				padding-left: 16px;
			}
		}
	}
}

.event-popup-icon-list {
	display: flex;
	flex-direction: row;
	position: relative;

	div {
		border-radius: 50%;
		width: 25px;
		height: 25px;
		position: relative;
		padding: 4px !important;

		@include minMediaquery('medium') {
			width: 32px;
			height: 32px;
		}

		img {
			width: 20px;
			position: absolute;
			top: 2.5px;
			left: 2.5px;

			@include minMediaquery('medium') {
				width: 30px;
			}
		}

		i {
			font-size: 23px;
			color: getcolor('white');
			line-height: 1.3;

			@include minMediaquery('medium') {
				font-size: 28px;
			}
		}
	}
}

.event-popup-downloads {
	margin-bottom: 16px;

	h4 {
		padding-bottom: 8px;
	}

	div {
		div {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;

			i {
				margin-left: -5px;
			}

			span {
				text-decoration: underline;
				padding-left: 16px;
			}
		}
	}
}

.event-popup-map {
	position: relative;
	padding-bottom: 32px;
}

.event-popup-footer {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 16px;

	> div {
		padding: 8px 16px 8px 16px;
		border-radius: 24px;
		background-color: getcolor('secondary');
	}
}
