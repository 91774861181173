.event-popup {
	.help {
		&.help-open {
			animation: slide-up .5s ease;
		}

		&.help-close {
			animation: slide-down-remove .5s ease;
		}
	}

	&.help-popup {
		background-color: getcolor('helpBackground');
		color: getcolor('lightFont');
		padding: 16px 16px 0 16px;
		width: calc(100% - 32px);

		.help-nav {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			width: 100%;
		}

		.help-listing {
			&:last-child {
				padding-bottom: 16px;
			}
		}

		.help-listing-element {
			width: 100%;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			margin-top: 16px;
			align-items: center;


			.help-listing-hits {
				background-color: getcolor('secondary');
				padding: 4px 12px 4px 12px;
				border-radius: 25px;
				color: getcolor('lightFont');
				position: relative;
				white-space: nowrap;
			}

			.help-listing-icon {
				color: getcolor('primary');
				background-color: getcolor('lightFont');
				border-radius: 50%;
				width: 32px;
				height: 32px;
				display: flex;
				position: relative;
				margin: auto 0;

				i {
					font-size: 24px;
					position: relative;
					margin: auto auto;
				}

				&.no-background {
					i {
						color: getcolor('white');
					}

					background-color: transparent;
				}

			}

			.help-listing-text {
				margin-left: 8px;
				font-weight: bold;
			}
		}
	}
}