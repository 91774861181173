.closeButton {
	background-color: getcolor('cancel');
	border-radius: 50%;
	color: getcolor('lightFont');
	width: 24px;
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;

	i {
		font-size: 16px;
	}
}

.confirm-button {
	background-color: getcolor('success');
	border-radius: 50%;
	color: getcolor('lightFont');
	width: 24px;
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;

	i {
		font-size: 16px;
	}
}