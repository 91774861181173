.event-popups {
	position: fixed;
	bottom: 0;
	width: inherit;
	max-width: inherit;
	z-index: 999999;

	.event-popup {
		width: 100%;
		background-color: getcolor('light');
	}
}