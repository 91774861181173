.to-top {
	position: fixed; //fallback wenn sticky nicht unterstützt wird (IE)
	position: sticky;
	bottom: 8px;
	left: 0;
	background-color: getcolor('secondary');
	width: 48px;
	height: 48px;
	margin: 8px;
	border-radius: 12px;

	> div {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;


		i {
			color: getcolor('white');
			font-size: 48px;
		}
	}
}