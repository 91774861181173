.nav-bar {
	width: 100%;
	background-color: getcolor('primary');

	.nav-bar-content {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin: 0 5% 0 5%;

		padding: 8px 0 8px 0;

		.nav-bar-search-filter {
			display: flex;
			flex-direction: row;
			justify-content: left;
			width: 30%;

			div {
				color: getcolor('primary');
				background-color: getcolor('lightFont');
				border-radius: 50%;
				width: 32px;
				height: 32px;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				margin: auto 16px auto 0;

				&.toggled {
					color: getcolor('lightFont');
					background-color: getcolor('secondary');
				}

				i {
					font-size: 24px;
				}
			}
		}

		.nav-bar-search-result {
			width: 40%;
			margin: auto 5% auto 5%;
			position: relative;

			div {
				display: flex;

				div {
					background-color: getcolor('secondary');
					padding: 4px 12px 4px 12px;
					border-radius: 25px;
					color: getcolor('lightFont');
					position: relative;
					margin: auto auto;
					line-height: 1;
					font-size: 12px;
					@include minMediaquery('medium') {
						font-size: inherit;
					}
				}
			}
		}

		.nav-bar-mode-switches {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			width: 30%;

			div {
				color: getcolor('primary');
				background-color: getcolor('lightFont');
				border-radius: 50%;
				width: 32px;
				height: 32px;
				display: flex;
				position: relative;
				margin: auto 0 auto 16px;
				align-items: center;
				justify-content: center;

				&.toggled {
					color: getcolor('lightFont');
					background-color: getcolor('secondary');
				}

				i {
					font-size: 24px;
				}
			}
		}
	}

	.navbar-search-block {
		display: flex;
		flex-direction: row;
		justify-content: center;
		padding-bottom: 10px;
		width: 90%;
		margin: 0 5% 0 5%;
		position: relative;

		input {
			border-radius: 20px 0 0 20px;
			padding: 0 0 0 10px;
			border: none;
			height: 25px;
			flex-grow: 1;
		}

		.nav-search-button {
			background-color: getcolor('secondary');
			color: getcolor('lightFont');
			font-weight: bold;
			font-size: 13px;
			padding: 0 10px 0 10px;
			border-radius: 0 20px 20px 0;
			line-height: 2;
			height: 25px;
		}

		.navbar-cancel-search {
			position: absolute;
			color: getcolor('cancel');
			right: 75px;
		}
	}
}